<template>
    <v-card>
        <v-card-text>
            <v-text-field class="mb-3"
                          v-model="searchString"
                          prepend-inner-icon="mdi-magnify"
                          label="Поиск..."
                          filled
                          background-color="transparent"
                          hide-details
                          ></v-text-field>
                        <center> <v-pagination
                     class='py-5'
            v-model="page"
            :length="pages"
            :total-visible="7"
            ></v-pagination>
            </center>
            <div class='v-main__wrap'>
                                        <v-overlay
          :absolute="true"
          :value="loading"
        ><v-progress-circular
        indeterminate
        size="64"
        ></v-progress-circular></v-overlay>
            
            <v-list>

                <v-list-item-group

                    color="primary">

                            <v-list-item
                                v-for="(file,index) in documents" :key="index"
                                @click="showFile(file)"
                                :alt="file.name"
                                >
                                <v-list-item-icon v-if="!$vuetify.breakpoint.mobile">
                                    <img height='210' :src="file.pages[0].src"/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip bottom >
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{file.name}}</span>
                                        </template>
                                        {{file.name}}
                                        </v-tooltip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-html='file.desc'></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                </v-list-item-group>
            </v-list>
</div>
        </v-card-text>
  
                <CoolLightBox
      :items="file.pages" 
      :fullScreen="true"
      :useZoomBar="true"
      :index="index"
      @close="index = null">
    </CoolLightBox>               
                    
    


    </v-card>
</template>
<style>
    .cool-lightbox .cool-lightbox__inner{
        padding: 35px 0;
        padding-bottom: 20px !important;
    }
</style>
<script>
    import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
    const debounce = function (f, ms) {
        let timer = null;
        return function (...args) {
            const onComplete = () => {
                f.apply(this, args);
                timer = null;
            }
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(onComplete, ms);
        };
    }
    export default {
        name: "index",
        data: () => ({
                page: 1,
                pages:1,
                searchString: '',
                index: null,
                documents: [],
                file: {
                    pages:[]
                },
                loading:true
            }),
components: {
    CoolLightBox,
  },
        methods: {
            fetchData() {
                this.loading=true
                this.$http.get('documents/get-books?string=' + this.searchString + '&page=' + this.page).then((response) => {
                    this.documents = response.data.books
                    this.pages = response.data.pages
                    this.loading=false
                })
            },
            showFile(file) {
                this.file = file
                this.index = 0
            },
            search: debounce(function () {

                this.fetchData()

            }, 500),
        },
        watch: {
            page() {
                this.fetchData()
            },
            searchString() {
                this.search()
            },
        },
        mounted() {
            this.fetchData()
        }
    }
</script>